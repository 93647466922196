* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Arial", sans-serif;
  background: #faf8ef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.title {
  text-align: center;
  margin-right: 40%;
}

.header {
  margin: 20px 0;
  color: #8d7f72;
}

.score_cont {
  float: right;
  gap: 20px;
  margin-bottom: 10px;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 40px;
}

.scoreboard {
  background: #bbada0;
  padding: 2%;
  border-radius: 2%;
  color: white;
  height: 5%;
}

.scoreboard h4 {
  background-color: #cdc1b4;
  padding: 4%;
  margin: 0 2%;
}

.current_score,
.best_score {
  padding: 8px;
  margin: 0 0 10px 0;
  background-color: #8f7a66;
  color: #ffffff;
  border-radius: 5px;
}

.btn {
  background: #8f7a66;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.btn-board {
  display: none;
}

#new_game:hover {
  background: #9f8b79;
}

.game_cont {
  --cell-size: 15vmin;
  --cell-gap: 1vmin;

  position: relative;
  display: grid;
  grid-template-columns: repeat(4, var(--cell-size));
  grid-template-rows: repeat(4, var(--cell-size));
  gap: var(--cell-gap);
}

.cell {
  background: #cdbfb4;
  border-radius: 0.5vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  font-weight: bold;
  color: #333;
}

.tile {
  position: absolute;
  top: calc(var(--y) * (var(--cell-size) + var(--cell-gap)));
  left: calc(var(--x) * (var(--cell-size) + var(--cell-gap)));
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--cell-size);
  height: var(--cell-size);
  border-radius: 0.5vmin;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 7.5vmin;
  font-weight: bold;
  animation: show 200ms;
  transition: 100ms;
}

@keyframes show {
  0% {
    opacity: 0.5;
    transform: scale(0);
  }
}

.merge {
  animation: merge 0.1s;
  animation-iteration-count: 1 ease-out;
}

/* Animation of a merging tile */
@keyframes merge {
  100% {
    transform: scale(1);
  }
}

table {
  width: 100%;
}

th,
td {
  padding: 0.8rem;
  background: #cdc1b4;
  color: white;
  text-align: left;
}

th {
  background: #bbada0;
  color: white;
}

footer {
  background: #8d7f72;
  color: white;
  text-align: center;
  padding: 0.5% 0;
  margin-top: auto;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.footer-content ul li {
  margin: 0;
  padding: 0;
  font-size: smaller;
}
p {
  font-size: smaller;
}
#player_name {
  padding: 4%;
  border-radius: 6%;
  background-color: #faf8f5;
  font-size: 0.9rem;
  color: #8d7f72;
  width: 90%;
  margin-bottom: 8%;
}
.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #8d7f72;
  padding: 10px 15px;
}
.menu {
  display: flex;
}
.menu li {
  padding-left: 30px;
  list-style-type: none;
}
.menu li a {
  display: inline-block;
  text-align: center;
  transition: 0.15s ease-in-out;
  position: relative;
}
.menu li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: white;
  transition: 0.15s ease-in-out;
}
.menu li a:hover::after {
  width: 100%;
}
.open-menu,
.close-menu {
  position: absolute;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  display: none;
}
.open-menu {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.close-menu {
  top: 20px;
  right: 20px;
}
#check {
  display: none;
}
@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 100;
    background-color: #8d7f72;
    transition: all 0.2s ease-in-out;
  }

  .btn-board {
    display: block;
  }

  .scoreboard {
    display: none;
  }

  .showBoard {
    display: block;
  }

  .menu li {
    margin-top: 40px;
    border-bottom: 1px solid #bbada0;
    width: 100%;
  }
  .menu li a {
    padding: 8px;
  }
  .open-menu,
  .close-menu {
    display: block;
  }
  #check:checked ~ .menu {
    right: 0;
  }
  .main-content {
    flex-direction: column;
    align-items: center;
  }
  .scoreboard {
    width: 100%;
    margin-top: 20px;
  }

  #second_player_board {
    display: none;
  }
  h6 {
    display: none;
  }
  #new_game,
  .score {
    font-size: 14px;
  }
}
.left {
  display: flex;
}
h6 {
  color: white;
  margin: auto;
  font-size: 2rem;
  margin-left: -40%;
}
img {
  width: 20%;
}
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-right: 2%;
}
.right a {
  color: #fff;
  text-decoration: none;
  margin-left: 5rem;
}
.score_cont {
  display: flex;
  flex-direction: row;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .game_cont {
    width: 100%;
  }

  .score,
  #new_game {
    font-size: 1.6vw;
  }
  h6 {
    display: none;
  }
  img {
    width: 30%;
  }
  .menu a {
    font-size: smaller;
  }
  .menu ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .main-content {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1025px) {
  .game_cont {
    width: 100%;
  }

  .score,
  #new_game {
    font-size: 0.8vw;
  }
  img {
    width: 20%;
  }
}
